<template>
  <n-grid cols="1 s:10" responsive="screen" style="padding-top: 30px">
    <n-gi span="8" offset="0 s:1"
      ><Table
        :data="data"
        :fieldsToHide="fieldsToHide"
        :globalActions="actions"
        :itemsActions="itemActions"
        :fieldsTooLong="fieldsTooLong"
        :filterableFields="filterableFields" /></n-gi
  ></n-grid>
</template>
<script>
import {
  PencilOutline as EditIcon,
  TrashBinOutline as DeleteIcon,
  PaperPlaneOutline as PublishIcon,
  ReturnDownBack as UnPublishIcon,
  PauseOutline as PauseIcon,
  AddOutline as NewPublicationIcon,
  EyeOutline as ViewPublicationIcon,
  CalendarClearOutline as CalendarIcon,
} from "@vicons/ionicons5";
import { Money as ProfitIcon } from "@vicons/carbon";
import { QuestionAnswerOutlined as QuestionsIcon } from "@vicons/material";
import { defineComponent, computed, onMounted, h } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { NGrid, NGi, useNotification, useDialog } from "naive-ui";
import Table from "@/components/TableBeta.vue";
import { PublicationState } from "@/models/index";
import { useI18n } from "vue-i18n";
import { themeOverrides } from "@/shared/constants";

export default defineComponent({
  components: { Table, NGrid, NGi },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const router = useRouter();
    const notification = useNotification();
    const dialog = useDialog();

    const publicationsRef = computed(() =>
      store.state.maintainer_publication.publications.filter(
        (item) => item._deleted === null
      )
    );

    const dataRef = computed(() =>
      publicationsRef.value.map((publication) => {
        return {
          id: publication.id,
          [t("commons.labels.title")]: publication.title,
          [t("commons.labels.category")]: publication.category.name,
          [t("commons.labels.state")]: t(`publications.states.${publication.state}`),
          state: publication.state,
        };
      })
    );

    const fieldsToHideRef = [
      "id",
      "user",
      "state",
      "createdAt",
      "updatedAt",
      "_version",
      "_deleted",
      "_lastChangedAt",
    ];

    const fieldsTooLongRef = computed(() => [
      t("commons.labels.title"),
      t("commons.labels.category"),
    ]);

    const filterableFieldsRef = computed(() => [
      t("commons.labels.category"),
      t("commons.labels.state"),
    ]);

    const loadData = async () => {
      await store.dispatch(
        "maintainer_publication/fillPublications",
        store.getters["auth/user_id"]
      );
    };

    const notificationHelper = async (message) =>
      notification.info({
        title: "Info",
        content: message,
        duration: 5000,
        closable: false,
      });

    onMounted(loadData);

    const changeAllStates = async (newState) => {
      let anyPublicationChanged = false;
      publicationsRef.value.forEach((publication) => {
        switch (newState) {
          case PublicationState.ACTIVE:
            if (publication.state === PublicationState.PAUSED) {
              anyPublicationChanged = true;
              publication.state = newState;
              store
                .dispatch("maintainer_publication/updatePublicationState", {
                  id: publication.id,
                  state: publication.state,
                  _version: publication._version,
                })
                .then(() => {
                  notificationHelper(
                    t("publications.messages.publicationActivated", {
                      publicationTitle: publication.title,
                    })
                  );
                });
            }
            break;
          case PublicationState.PAUSED:
          case PublicationState.INACTIVE:
            if (
              publication.state === PublicationState.ACTIVE ||
              (newState === PublicationState.INACTIVE &&
                publication.state === PublicationState.PAUSED)
            ) {
              anyPublicationChanged = true;
              publication.state = newState;
              store
                .dispatch("maintainer_publication/updatePublicationState", {
                  id: publication.id,
                  state: publication.state,
                  _version: publication._version,
                })
                .then(() => {
                  notificationHelper(
                    newState === PublicationState.INACTIVE
                      ? t("publications.messages.publicationDeactivated", {
                          publicationTitle: publication.title,
                        })
                      : t("publications.messages.publicatitionPaused", {
                          publicationTitle: publication.title,
                        })
                  );
                });
            }
            break;
        }
      });
      if (!anyPublicationChanged) {
        notificationHelper(t("publications.messages.noPublicationsChanged"));
      }
    };

    return {
      t,
      themeOverrides,
      data: dataRef,
      fieldsToHide: fieldsToHideRef,
      fieldsTooLong: fieldsTooLongRef,
      filterableFields: filterableFieldsRef,
      actions: [
        {
          label: t("commons.actions.add", {
            reference: t("commons.labels.publication", 1),
          }),
          key: "new-publication",
          icon: NewPublicationIcon,
          props: {
            onClick: () => {
              router.push({ name: "create-publication" });
            },
          },
        },
        {
          label: t("commons.labels.profit", 2),
          key: "my-profits",
          icon: ProfitIcon,
          props: {
            onClick: () => {
              router.push({ name: "my-profits" });
            },
          },
        },
        {
          label: t("commons.actions.change", {
            reference: t("commons.labels.status"),
          }),
          disabled: true,
        },
        {
          label: t("publications.actions.changeState.publishAll"),
          key: "active-all",
          icon: PublishIcon,
          props: {
            onClick: () => {
              dialog.warning({
                title: t("commons.label.confirmation"),
                content: () =>
                  h("div", {}, t("publications.dialogConfirmation.publishAll.content")),
                positiveText: t("commons.confirmation.yes"),
                negativeText: t("commons.confirmation.no"),
                onPositiveClick: () => {
                  changeAllStates(PublicationState.ACTIVE);
                },
              });
            },
          },
        },
        {
          label: t("publications.actions.changeState.pauseAll"),
          key: "pause-all",
          icon: PauseIcon,
          props: {
            onClick: () => {
              dialog.warning({
                title: t("commons.label.confirmation"),
                content: () =>
                  h("div", {}, t("publications.dialogConfirmation.pauseAll.content")),
                positiveText: t("commons.confirmation.yes"),
                negativeText: t("commons.confirmation.no"),
                onPositiveClick: () => {
                  changeAllStates(PublicationState.PAUSED);
                },
              });
            },
          },
        },
        {
          label: t("publications.actions.changeState.deactivateAll"),
          key: "inactive-all",
          icon: UnPublishIcon,
          props: {
            onClick: () => {
              dialog.warning({
                title: t("publications.dialogConfirmation.deactivateAll.label"),
                content: () =>
                  h(
                    "div",
                    {},
                    t("publications.dialogConfirmation.deactivateAll.content")
                  ),
                positiveText: t("commons.confirmation.yes"),
                negativeText: t("commons.confirmation.no"),
                onPositiveClick: () => {
                  changeAllStates(PublicationState.INACTIVE);
                },
              });
            },
          },
        },
      ],
      itemActions: (item) => {
        let actions = [];
        if (item.state === PublicationState.ACTIVE) {
          actions.push({
            label: t("commons.actions.view"),
            key: "view",
            icon: ViewPublicationIcon,
            props: {
              onClick: () => {
                router.push({
                  name: "view-publication",
                  params: { publicationId: item.id },
                });
              },
            },
          });
        }

        if (item.state != PublicationState.INACTIVE) {
          actions.push({
            label: t("commons.actions.update"),
            key: "edit",
            icon: EditIcon,
            props: {
              onClick: () => {
                router.push({
                  name: "update-publication",
                  params: { publicationId: item.id },
                });
              },
            },
          });

          actions.push({
            label: t("publications.itemActions.labels.calendar"),
            key: "calendar",
            icon: CalendarIcon,
            props: {
              onClick: () => {
                router.push({
                  name: "calendar",
                  params: { publicationId: item.id },
                });
              },
            },
          });

          actions.push({
            label: t("publications.itemActions.labels.faqs"),
            key: "questions",
            icon: QuestionsIcon,
            props: {
              onClick: () => {
                router.push({
                  name: "questions",
                  params: { publicationId: item.id },
                });
              },
            },
          });
        }
        actions.push({
          label: t("commons.actions.delete"),
          key: "delete",
          icon: DeleteIcon,
          props: {
            onClick: () => {
              dialog.warning({
                title: t("publications.dialogConfirmation.deactivate.title"),
                content: () =>
                  h(
                    "div",
                    {},
                    t("publications.dialogConfirmation.deactivate.content", {
                      publicationTitle: item.title,
                    })
                  ),
                positiveText: t("commons.confirmation.yes"),
                negativeText: t("commons.confirmation.no"),
                onPositiveClick: async () => {
                  store
                    .dispatch("maintainer_publication/deletePublication", item)
                    .finally(async () => {
                      await loadData();
                    });
                },
              });
            },
          },
        });
        return actions;
      },
    };
  },
});
</script>

<style scoped>
:deep(.n-card__content) {
  padding: unset;
  padding-top: unset !important;
}
.header {
  font-weight: 500;
  font-size: 14px;
  background-color: v-bind(themeOverrides.common.primaryColor);
  color: v-bind(themeOverrides.common.whiteColor);
  @media screen and (max-width: 799px) {
    font-size: 12px;
  }
}
.body {
  align-content: center;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: v-bind(themeOverrides.common.grayLightColor);
}

.grid {
  height: 30px;
  padding-top: 5px;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: v-bind(themeOverrides.common.graySemiColor);
}
.no-border {
  border-right-style: unset;
}

.pt-0 {
  padding-top: 0px;
}
.massive-action-selector {
  margin-left: 94.5%;
  @media screen and (max-width: 799px) {
    margin-left: 80%;
  }
}

.n-tag {
  font-size: 10px;
}
</style>
